import * as React from 'react'
import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { ContractMissingIcon, NoLinkIcon, PlusCircleIcon } from 'icons'
import moment from 'moment'
import theme from 'theme'
import { formatCurrencySymbol } from 'utils'
import { getProviderLogo } from 'utils/commissions'
import EventEmitter, { UPDATE_EVENT_CONTRACTS } from 'utils/events'
import { detachContractFromReportingClient } from '../../../../../component/Modals/ModalClientContractAffiliation/core/ClientContract.services'
import { IAPIClientContract, IAPICommissionClient } from 'api/interfaces/entities'
import EmptyBlock from '../EmptyBlock'
import { connect } from 'react-redux'
import { ModalUploadContracts } from 'component/Modals'
import { REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL } from 'utils/router/constants'

interface IProps {
  automaticallyAttachedContracts: IAPIClientContract[]
  manuallyAttachedContracts: IAPIClientContract[]
  client: IAPICommissionClient
  user: any
}

const ContractsTab = ({
  automaticallyAttachedContracts,
  manuallyAttachedContracts,
  client,
  user,
}: IProps) => {
  const [error, setError] = React.useState('')
  const [importModalOpen, setImportModalOpen] = React.useState(false)

  const handleContactAffiliation = async (contract: IAPIClientContract) => {
    if (!client || !contract) return
    try {
      await detachContractFromReportingClient({ clientId: client.id, contractId: contract.id })
      EventEmitter.dispatch(UPDATE_EVENT_CONTRACTS)
    } catch (e: any) {
      setError(e.message)
    }
  }

  const handleImportContracts = () => {
    setImportModalOpen(true)
  }

  return (
    <>
      <List
        sx={{
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          px: 6,
          py: 0,
        }}
      >
        <ListItem sx={{ p: 0, height: 32 }}>
          <ListItemText
            primary={
              <>
                <Typography component="span" mr={6} variant="subtitle2_m">
                  Contrats
                </Typography>
                <img
                  width="20px"
                  height="20px"
                  src={getProviderLogo('')}
                  alt="contrat"
                  style={{ visibility: 'hidden' }}
                />
              </>
            }
            sx={{ m: 0, flex: 2 }}
          />
          <ListItemText
            primary="Identifiant"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 1 }}
          />
          <ListItemText
            primary="Souscrit le"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 1 }}
          />
          <ListItemText
            primary="Valorisation"
            primaryTypographyProps={{ variant: 'subtitle2_m' }}
            sx={{ m: 0, flex: 1 }}
          />
        </ListItem>
      </List>

      <Wrapper>
        <Typography variant="sectionHeading" component="h3" sx={{ opacity: 0.7 }} mb={2} pl={2}>
          Contrats issus de la base de données
        </Typography>
        {!automaticallyAttachedContracts.length ? (
          <EmptyBlock
            title={`Aucun contrat n’a été trouvé pour ${client.firstName} ${client.lastName}`}
            description={`L’import de votre base contrats du ${moment(
              user.organization.contractsLastUpdatedAt
            ).format('Do MMMM YYYY')} ne contenait aucun contrat.`}
            icon={<ContractMissingIcon sx={{ fontSize: 88, mb: 3 }} />}
            cta={{
              label: 'Mettre à jour la base de contrats',
              onClick: handleImportContracts,
              icon: <PlusCircleIcon sx={{ mr: '4px' }} />,
            }}
          />
        ) : (
          automaticallyAttachedContracts.map((contract) => (
            <ListItem
              sx={{
                background: theme.palette.background.default,
                borderRadius: 1,
                mb: 2,
                height: 44,
              }}
              key={contract.id}
            >
              <ListItemText
                primary={
                  <>
                    <img
                      width="20px"
                      height="20px"
                      src={getProviderLogo(contract.insurance)}
                      alt={contract.insurance}
                    />
                    <Typography component="span" ml={2} variant="subtitle1_m">
                      {contract.productName ?? contract.externalProductName}
                    </Typography>
                  </>
                }
                sx={{ flex: 2 }}
              />
              <ListItemText
                primary={contract.number}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary={contract.subscriptionDate ? moment(contract.subscriptionDate).format('DD/MM/YYYY') : '-'}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary={formatCurrencySymbol(contract.value)}
                primaryTypographyProps={{ variant: 'number1' }}
                sx={{ flex: 1 }}
              />
            </ListItem>
          ))
        )}
        <Typography
          variant="sectionHeading"
          component="h3"
          sx={{ opacity: 0.7 }}
          mb={2}
          mt={automaticallyAttachedContracts.length > 0 ? 3 : 0}
          pl={2}
        >
          Contrats Rattachés manuellement
        </Typography>
        {!manuallyAttachedContracts.length ? (
          <EmptyBlock
            title={`Aucun contrat n’a été rattaché manuellement à ${client.firstName} ${client.lastName}`}
            description="Pour rattacher manuellement des contrats à ce client, rendez-vous sur l’onglet “Contrats à rattacher manuellement”."
            link={{
              label: 'Ouvrir la liste des “Contrats à rattacher manuellement”',
              url: REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL,
            }}
          />
        ) : (
          manuallyAttachedContracts.map((contract) => (
            <ListItem
              sx={{
                borderRadius: 1,
                mb: 2,
                border: '1px dashed rgba(66, 65, 59, 0.20)',
                height: 44,
              }}
              key={contract.id}
            >
              <ListItemText
                primary={
                  <>
                    <img
                      width="20px"
                      height="20px"
                      src={getProviderLogo(contract.insurance)}
                      alt={contract.insurance}
                    />
                    <Typography component="span" fontSize={14} fontWeight={500} ml={2}>
                      {contract.productName}
                    </Typography>
                  </>
                }
                sx={{ flex: 2 }}
              />
              <ListItemText
                primary={contract.number}
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary="-"
                primaryTypographyProps={{ variant: 'subtitle1' }}
                sx={{ opacity: 0.7, flex: 1 }}
              />
              <ListItemText
                primary={
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1_m">
                      {!isNaN(contract.value) ? `${formatCurrencySymbol(contract.value)}` : '-'}
                    </Typography>
                    <IconButton
                      color="errorLight"
                      sx={{ height: 30, width: 30 }}
                      onClick={() => handleContactAffiliation(contract)}
                    >
                      <NoLinkIcon sx={{ width: 16 }} />
                    </IconButton>
                  </Stack>
                }
                sx={{ flex: 1 }}
              />
            </ListItem>
          ))
        )}
      </Wrapper>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert
          onClose={() => setError('')}
          severity="error"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {error}
        </Alert>
      </Snackbar>
      <ModalUploadContracts
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        updateDataBase={true}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ContractsTab)

const Wrapper = styled(List)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}))
