import * as React from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { FileMissingIcon, PlusCircleIcon, InfoIcon } from 'icons'
import { getProviderLogo } from 'utils/commissions'
import { Dropdown } from 'component/Inputs'
import CommissionTableRow from './CommissionTableRow'
import { formatCurrencySymbol } from 'utils'
import {
  IAPICommissionClient,
  IAPICommissionFees,
  IAPICommissionSummary,
} from 'api/interfaces/entities'
import {
  getCommissionContracts,
  getCommissions,
  getCommissionsSummary,
  getCommissionsYears,
} from '../../core/Clients.services'
import Theme from 'theme'
import { IDropDownOption } from 'component/Inputs/Dropdown'
import EmptyBlock from '../EmptyBlock'
import { ModalContext } from 'utils/hooks/modalBehavior'

interface IProps {
  client: IAPICommissionClient
}

const CommissionsTab = ({ client }: IProps) => {
  const [loading, setLoading] = React.useState(true)
  const [commissions, setCommissions] = React.useState<any[]>([])
  const [yearTotal, setYearTotal] = React.useState<{
    year?: number | undefined
    fees: IAPICommissionFees[]
  }>({
    fees: [],
  })
  const [selectedFilter, setSelectedFilter] = React.useState<{
    contractNumber?: string
    year?: string
  }>({})
  const [yearsFilters, setYearsFilters] = React.useState<IDropDownOption[]>([])
  const [contractsFilters, setContractsFilters] = React.useState<IDropDownOption[]>([
    {
      display: 'Tous les contrats',
    },
  ])
  const [summaryData, setSummaryData] = React.useState<IAPICommissionSummary>({
    seniority: {
      years: 0,
      months: 0,
    },
    insurances: [],
    contractValue: 0,
  })
  const modal = React.useContext(ModalContext)

  const updateContractsFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      contractNumber: event.target.value as string,
    }))
  }

  const updateYearsFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      year: event.target.value as string,
    }))
  }

  const fetchSummaryData = React.useCallback(async () => {
    if (!client) return
    const res = await getCommissionsSummary({ clientId: client.id })
    if (!res) {
      return
    }
    setSummaryData(res.summary)
  }, [client])

  const fetchCommissionsContracts = React.useCallback(async () => {
    if (!client) return

    const res = await getCommissionContracts({ clientId: client.id })
    if (!res) {
      return
    }

    setContractsFilters([
      {
        display: 'Tous les contrats',
      },
      ...res.contracts.map((contract) => ({
        value: contract.number,
        display: contract.number,
        logo: getProviderLogo(contract.provider),
      })),
    ])
  }, [client])

  const fetchCommissionsYear = React.useCallback(async () => {
    if (!client) return
    const res = await getCommissionsYears({ clientId: client.id, filterParams: selectedFilter })

    if (!res) {
      return
    }
    setYearsFilters(
      res.years
        .sort((year1, year2) => Number(year2) - Number(year1))
        .map((year) => ({ display: year.toString(), value: year.toString() }))
    )
  }, [selectedFilter, client])

  const fetchCommissions = React.useCallback(async () => {
    if (!client) return
    let res: any = {}

    const filterParams: any = {
      contractNumber: selectedFilter.contractNumber?.toString(),
      year: selectedFilter.year
        ? selectedFilter.year.toString()
        : yearsFilters[0]?.value?.toString(),
    }

    if (yearsFilters.length !== 0) {
      res = await getCommissions({
        clientId: client.id,
        setLoading,
        filterParams,
      })
    }

    if (!res || !res.commissions) {
      setLoading(false)
      return
    }

    const formatedRes: any = Object.entries(res.commissions)
      .slice(2)
      .map((d: any) => ({ month: d[0], fees: d[1].fees }))

    setYearTotal(res.commissions.yearTotal)
    setCommissions(formatedRes)
  }, [client, yearsFilters, selectedFilter])

  React.useEffect(() => {
    fetchSummaryData()
  }, [fetchSummaryData])

  React.useEffect(() => {
    fetchCommissionsContracts()
  }, [fetchCommissionsContracts])

  React.useEffect(() => {
    fetchCommissionsYear()
  }, [fetchCommissionsYear])

  React.useEffect(() => {
    fetchCommissions()
  }, [selectedFilter, fetchCommissions])

  const getSeniority = (years: number, months: number): string => {
    if (years === 0 && months === 0) {
      return '-'
    }

    let seniority = '';

    if (years >= 1) {
      seniority += years > 1 ? `${years} ans` : `${years} an`;
    }

    if (months > 0) {
      seniority += years > 0 ? ` et ${months} mois` : `${months} mois`;
    }

    return seniority;
  };

  return (
    <>
      <Wrapper direction="row" alignItems="start" gap={4}>
        <Box sx={{ minWidth: 184 }}>
          <Typography component="p" variant="sectionHeading" sx={{ opacity: 0.7 }} mb={1}>
            ancienneté
          </Typography>
          <Typography component="p" variant="body2_m">
            {getSeniority(summaryData.seniority.years, summaryData.seniority.months)}
          </Typography>
        </Box>
        <Box sx={{ minWidth: 184 }}>
          <Typography component="p" variant="sectionHeading" sx={{ opacity: 0.7 }} mb={1}>
            assureurs
          </Typography>
          <Stack gap={1} direction="row" alignItems="center">
            {summaryData.insurances.map((insurance) => (
              <img
                width="24px"
                height="24px"
                src={getProviderLogo(insurance)}
                alt={insurance}
                key={insurance}
              />
            ))}
          </Stack>
        </Box>
        <Box sx={{ minWidth: 184 }}>
          <Stack direction="row" alignItems="center" gap={1} mb={1}>
            <Typography component="p" variant="sectionHeading" sx={{ opacity: 0.7 }}>
              valorisation des contrats
            </Typography>
            <Tooltip
              title={
                <Box sx={{ width: 300 }}>
                  <Typography
                    component="p"
                    variant="subtitle1_m"
                    color="neutralLight.contrastText"
                    mb={1}
                  >
                    Valorisation indicative
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="neutralLight.contrastText"
                    sx={{ opacity: 0.7 }}
                    mb={1}
                  >
                    La valorisation indiquée est celle présente dans l’import de votre base de
                    données.
                  </Typography>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="neutralLight.contrastText"
                    sx={{ opacity: 0.7 }}
                  >
                    Elle n’est donc pas mise à jour en temps réel est n’est présentée qu’à titre
                    indicatif.
                  </Typography>
                </Box>
              }
            >
              <Box height={20}>
                <InfoIcon color="primary" />
              </Box>
            </Tooltip>
          </Stack>
          <Typography component="p" variant="number1">
            {formatCurrencySymbol(summaryData.contractValue)}
          </Typography>
        </Box>
      </Wrapper>
      <Divider />
      <Wrapper>
        <Stack direction="row" alignItems="center" mb={2}>
          <Typography variant="h3" component="h3">
            Détail des commissions
          </Typography>
          <Dropdown
            id="contracts-filter"
            value={
              contractsFilters.find((filter) => filter.value === selectedFilter.contractNumber)
                ?.value
            }
            onChange={updateContractsFilter}
            placeholder="Filtrer par"
            options={contractsFilters}
            size="small"
            sx={{ ml: 'auto' }}
          />
          {yearsFilters.length > 0 && (
            <Dropdown
              id="years-filter"
              value={
                yearsFilters.find((filter) => filter.value === selectedFilter.year)?.value ||
                yearsFilters[0]?.value
              }
              onChange={updateYearsFilter}
              placeholder="Filtrer par"
              options={yearsFilters}
              size="small"
              sx={{ ml: 1 }}
            />
          )}
        </Stack>
        {!loading && (!commissions.length || !yearTotal.fees.length) ? (
          <EmptyBlock
            title={`Aucune commission n’a été trouvée pour ${client.firstName} ${
              client.lastName
            } pour la période ${selectedFilter.contractNumber ? 'et le contrat' : ''} sélectionné${
              selectedFilter.contractNumber ? 's' : 'e'
            }.`}
            description="Pour obtenir le cumul des commissions pour ce client, importez les bordereaux du/des partenaires concernés."
            icon={<FileMissingIcon sx={{ fontSize: 88, mb: 3 }} />}
            cta={{
              label: 'Ajouter un bordereau',
              onClick: () => {
                modal.setCurrentModal({
                  name: 'ModalUploadMultipleDockets',
                  initialSelectedInsurance: undefined,
                  collapsed: false,
                })
              },
              icon: <PlusCircleIcon sx={{ mr: '4px' }} />,
            }}
          />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table aria-label="Tableau des preuves de vérification">
              <CustomTableHead>
                <TableRow>
                  <StyledHeaderCell></StyledHeaderCell>
                  <StyledHeaderCell className="header-cell--cumulated">Cumulé</StyledHeaderCell>
                  <StyledHeaderCell>Frais de gest.</StyledHeaderCell>
                  <StyledHeaderCell>Mandats</StyledHeaderCell>
                  <StyledHeaderCell>Majorations</StyledHeaderCell>
                  <StyledHeaderCell>Rétro. OPCVM</StyledHeaderCell>
                  <StyledHeaderCell>Arbitrages</StyledHeaderCell>
                  <StyledHeaderCell>Versements</StyledHeaderCell>
                  <StyledHeaderCell>Upfronts</StyledHeaderCell>
                  <StyledHeaderCell>Autres</StyledHeaderCell>
                </TableRow>
              </CustomTableHead>
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      align="center"
                      sx={{ border: `1px solid ${Theme.palette.divider}` }}
                    >
                      <CircularProgress size={20} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <CustomYearTableBody>
                    <CommissionTableRow row={yearTotal} yearRow={true} />
                  </CustomYearTableBody>
                  <CustomTableBody>
                    {commissions.map((row) => (
                      <CommissionTableRow row={row} yearRow={false} key={row.month} />
                    ))}
                  </CustomTableBody>
                </>
              )}
            </Table>
          </TableContainer>
        )}
      </Wrapper>
    </>
  )
}

export default CommissionsTab

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
}))

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  backgroundColor: theme.palette.background.inverted,
  paddingTop: 0,
  paddingBottom: 0,
  height: 40,
  color: `${theme.palette.secondary.contrastText}B3`,
  textAlign: 'right',

  '&.header-cell--cumulated': {
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,
  },
}))

const CustomTableHead = styled(TableHead)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.inverted}`,
  borderBottomWidth: 2,
  borderRadius: 4,
}))

const CustomYearTableBody = styled(TableBody)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
}))

const CustomTableBody = styled(TableBody)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderTop: 'none',
  borderRadius: 4,
}))
