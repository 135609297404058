import React, { useMemo } from 'react'
import { Stack } from '@mui/system'
import { getProviderLogo } from '../../../../../../../utils/commissions'
import { formatCurrencySymbol } from '../../../../../../../utils'
import { IInsuranceMetric } from '../../index'

interface IInsuranceItem {
  name: string,
  provider: string,
  amount: number,
  percentage: number
  leftPos: number
}

interface IInsurances {
  perInsurance: IInsuranceMetric[]
}

const InsuranceItem = (props: IInsuranceItem) => {
  const { name, provider, amount, percentage, leftPos } = props

  const formatAmount = useMemo(() => {
    return formatCurrencySymbol(amount ? amount : '')
  }, [amount])

  const rangeStyle = {
    width: (percentage * 100) + '%',
    left: leftPos + '%'
  }

  const displayPercentage = useMemo(() => {
    return Math.ceil(percentage * 100)
  }, [percentage])

  return (
    <div className="partner-item">
      <div className="partner-item__logo">
        {provider !== 'broker' ? (
          <img
            src={getProviderLogo(provider)}
            width={40}
            height={40}
            alt={`${provider} logo`}
          />
        ) : (
          <div className="partner-item__broker">C</div>
        )}
      </div>
      <div className="partner-item__content">
        <div className="partner-item__content-metas">
          <div className="partner-item__metas">{name} ({displayPercentage.toString()}%)</div>
          <div className="partner-item__amount">{formatAmount}</div>
        </div>
        <div className="partner-item__content-range">
          <div className="partner-item__range" style={rangeStyle}></div>
        </div>
      </div>
    </div>
  )
}

function Insurances(props: IInsurances): React.ReactElement {
  const {perInsurance} = props
  let amountLeftPos = 0

  const getLeftPost = (index: number) => {
    if(index > 0) {
      amountLeftPos += perInsurance[index - 1].percentage * 100
      return amountLeftPos
    }
    else return 0
  }

  return (
    <Stack py={'24px'} gap={2} direction="column" className="ace-partners">
      {perInsurance?.map((p, index) => (
        <InsuranceItem key={index} name={p.insurance.name} provider={p.insurance.provider} amount={p.amount} percentage={p.percentage} leftPos={getLeftPost(index)} />
      ))}
    </Stack>
  )
}

export default Insurances;