import * as React from 'react'

import { LayoutMenu } from 'component/Layouts'
import { Box, Button, Chip, Stack, styled, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import { Route, RouteComponentProps, Switch, useLocation, withRouter } from 'react-router-dom'
import {
  REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL,
  REPORTING_CLIENT_ANALYSIS_DATA_URL,
} from 'utils/router/constants'
import { ClientsAffiliation, ClientsList } from './components'
import { ModalUploadContracts } from 'component/Modals'
import { getContractsToAffiliate } from './core/Clients.services'
import { IAPICommissionContractToAffiliate } from 'api/interfaces/entities'
import EventEmitter, { UPDATE_EVENT_CONTRACTS } from 'utils/events'

import { connect } from 'react-redux'
import { ModalContext } from 'utils/hooks/modalBehavior'

interface IProps extends RouteComponentProps {
  user: any
}

const ClientsAnalysis = (props: IProps) => {
  const { history, user } = props
  const location = useLocation()
  const [tab, setTab] = React.useState(REPORTING_CLIENT_ANALYSIS_DATA_URL)
  const [importModalOpen, setImportModalOpen] = React.useState(false)
  const [initialContractsFetch, setInitialContractsFetch] = React.useState<{
    contracts: IAPICommissionContractToAffiliate[]
    contractsCount: number
  } | null>(null)
  const modal = React.useContext(ModalContext)

  React.useEffect(() => {
    if (location.pathname !== tab) {
      setTab(location.pathname)
    }
  }, [location, tab])

  // @ts-ignore
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
    history.push(newValue)
  }

  const handleImportContracts = () => {
    setImportModalOpen(true)
  }

  const fetchContracts = async () => {
    const res = await getContractsToAffiliate({
      filterParams: {
        page: 0,
        count: 25,
        search: '',
      },
    })

    if (!res) {
      return
    }

    setInitialContractsFetch(res)
  }

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_CONTRACTS, () => {
      fetchContracts()
    })
    fetchContracts()
  }, [])

  const handleCloseModal = () => {
    setImportModalOpen(false)
    modal.setCurrentModal(null)
  }

  let lastUpdateString = undefined;

  if (user.organization.contractsLastUpdatedAt) {
    const diff = moment(user.organization.contractsLastUpdatedAt)
    const seconds = moment(user.organization.contractsLastUpdatedAt).diff('seconds')
    
    if (seconds < 60) {
      lastUpdateString = 'à l\'instant';
    } else {
      lastUpdateString = diff.fromNow();
    }
  }

  return (
    <LayoutMenu location="reportings" defaultMenuOpen={true}>
      <Box bgcolor="background.inverted">
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={4}>
          <Box sx={{ maxWidth: 680 }}>
            <Typography variant="h2" color="primary.contrastText">
              Analyse des clients
            </Typography>
          </Box>

          {user.organization.contractsLastUpdatedAt ? (
            <Box>
              <Button sx={{ mb: 1 }} onClick={handleImportContracts}>
                Mettre à jour la base de contrats
              </Button>
              <Typography
                variant="subtitle2"
                color="primary.contrastText"
                sx={{ opacity: 0.7 }}
                align="center"
              >
                Dernière mise à jour{' '}
                {lastUpdateString}
              </Typography>
            </Box>
          ) : (
            <Button onClick={handleImportContracts}>Importer ma base de données contrats</Button>
          )}
        </Stack>
        <CustomTab
          value={tab}
          onChange={handleTabChange}
          aria-label="clients analysis navigation"
          role="navigation"
        >
          <Tab
            disableRipple={true}
            label={<span className="tab-text">Base de données clients</span>}
            value={REPORTING_CLIENT_ANALYSIS_DATA_URL}
            sx={{ fontWeight: 500 }}
          />
          <Tab
            disableRipple={true}
            value={REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL}
            sx={{ fontWeight: 500 }}
            label={
              <Stack direction="row" alignItems="center" spacing={2} color="secondary.contrastText">
                <span className="tab-text">Contrats à rattacher manuellement</span>
                {initialContractsFetch && initialContractsFetch?.contractsCount > 0 && (
                  <Chip
                    label={initialContractsFetch.contractsCount}
                    color="warning"
                    size="small"
                    sx={{ ml: 2, minWidth: 24 }}
                  />
                )}
              </Stack>
            }
          />
        </CustomTab>
      </Box>
      <Stack px={4} py={3}>
        <Switch>
          <Route
            key="clients analysis data"
            path={REPORTING_CLIENT_ANALYSIS_DATA_URL}
            component={ClientsList}
            exact
          />
          <Route
            key="clients analysis affiliation"
            path={REPORTING_CLIENT_ANALYSIS_AFFILIATION_URL}
            render={() => <ClientsAffiliation initialContractsFetch={initialContractsFetch} />}
            exact
          />
        </Switch>
      </Stack>
      <ModalUploadContracts
        open={
          importModalOpen ||
          !!(modal.currentModal && modal.currentModal.name === 'ModalUploadContracts')
        }
        onClose={handleCloseModal}
        updateDataBase={!!user.organization.contractsLastUpdatedAt}
      />
    </LayoutMenu>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}
export default withRouter(connect(mapStateToProps)(ClientsAnalysis))

const CustomTab = styled(Tabs)(({ theme }) => ({
  button: {
    '.tab-text': {
      color: theme.palette.secondary.contrastText,
      opacity: 0.7,
    },

    '&.Mui-selected .tab-text': {
      color: theme.palette.secondary.contrastText,
      opacity: 1,
    },
  },
  '.MuiTabs-indicator': {
    height: 4,
    borderRadius: `4px 4px 0px 0px`,
  },
}))
