export const UPDATE_EVENT_MODAL_CONFIRM = 'UPDATE_EVENT_MODAL_CONFIRM'
export const UPDATE_EVENT_SUPPORTS = 'UPDATE_EVENT_SUPPORTS'
export const UPDATE_EVENT_SUPPORT = 'UPDATE_EVENT_SUPPORT'
export const UPDATE_EVENT_USER_INSURANCE = 'UPDATE_EVENT_USER_INSURANCE'
export const UPDATE_EVENT_SUPPORT_ESIGN = 'UPDATE_EVENT_SUPPORT_ESIGN'
export const UPDATE_EVENT_INPUT = 'UPDATE_EVENT_INPUT'
export const UPDATE_EVENT_CURRENCY = 'UPDATE_EVENT_CURRENCY'
export const UPDATE_EVENT_CLIENTS_HAS_SUPPORT = 'UPDATE_EVENT_CLIENTS_HAS_SUPPORT'
export const UPDATE_EVENT_DOCKETS = 'UPDATE_EVENT_DOCKETS'
export const UPDATE_EVENT_DOCKET = 'UPDATE_EVENT_DOCKET'
export const UPDATE_EVENT_DOCKETS_IMPORTS = 'UPDATE_EVENT_DOCKETS_IMPORTS'
export const UPDATE_EVENT_UCITS = 'UPDATE_EVENT_UCITS'
export const UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS = 'UPDATE_EVENT_INSURANCE_FEE_UCITS_ITEMS'
export const UPDATE_EVENT_INSURANCE_PRODUCTS = 'UPDATE_EVENT_INSURANCE_PRODUCTS'
export const UPDATE_EVENT_IFEFS = 'UPDATE_EVENT_IFEFS'
export const UPDATE_EVENT_IFEFIS = 'UPDATE_EVENT_IFEFIS'
export const UPDATE_EVENT_CLIENTS = 'UPDATE_EVENT_CLIENTS'
export const UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE = 'UPDATE_EVENT_INSURANCE_FEE_ARBITRAGE'
export const UPDATE_EVENT_INSURANCE_FEE_DEPOSIT = 'UPDATE_EVENT_INSURANCE_FEE_DEPOSIT'
export const UPDATE_EVENT_INSURANCE_FEE_UCITS = 'UPDATE_EVENT_INSURANCE_FEE_UCITS'
export const UPDATE_EVENT_INSURANCE_FEE_UPFRONT = 'UPDATE_EVENT_UPFRONT'
export const UPDATE_EVENT_DOCKETS_REVALIDATE = 'UPDATE_EVENT_DOCKETS_REVALIDATE'
export const UPDATE_EVENT_DOCKETS_INVALIDATE = 'UPDATE_EVENT_DOCKETS_INVALIDATE'
export const UPDATE_EVENT_CONTRACTS = 'UPDATE_EVENT_CONTRACTS'
export const UPDATE_EVENT_USER = 'UPDATE_EVENT_USER'

class EventEmitter {
  events: any

  constructor() {
    this.events = {
      UPDATE_EVENT_MODAL_CONFIRM: [],
      UPDATE_EVENT_SUPPORTS: [],
      UPDATE_EVENT_SUPPORT: [],
      UPDATE_EVENT_CLIENTS: [],
      UPDATE_EVENT_CONTRACTS: [],
      UPDATE_EVENT_SUPPORT_ESIGN: [],
      UPDATE_EVENT_INPUT: [],
      UPDATE_EVENT_CURRENCY: [],
      UPDATE_EVENT_CLIENTS_HAS_SUPPORT: [],
      UPDATE_EVENT_DOCKETS: [],
      UPDATE_EVENT_USER: []
    }
  }

  dispatch(event: string, data?: any) {
    if (!this.events[event]) return
    this.events[event].forEach((callback: Function) => callback(data))
  }

  subscribe(event: string, callback: Function) {
    if (!this.events[event]) this.events[event] = []
    this.events[event].push(callback)
  }

  remove(event: string) {
    if (this.events[event]) this.events[event] = []
  }
}
const eventEmitter = new EventEmitter()
export default eventEmitter
