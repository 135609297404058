import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { customFetch } from 'api/customFetch'
import { API_ME_URL } from 'api/constants'
import {
  FILE_TRANSFER_URL,
  FROZEN_ASSETS_ENTITIES_URL,
  HOMEPAGE_URL,
  LOGIN_URL,
  REPORTING_URL,
  SUBSCRIPTION_URL,
} from 'utils/router/constants'
import { USER_UPDATE_INFOS } from 'store/constants/user'
import EventEmitter, { UPDATE_EVENT_USER } from 'utils/events'

function Middleware(props: any) {
  const { connected, children, history, dispatch, user } = props
  const [hasUserInfos, setUserInfos] = useState(false)

  const loginUrl = history.location.pathname === LOGIN_URL
  const sharedUrl = history.location.pathname.includes('shared')
  const subscribeUrl = history.location.pathname.includes(SUBSCRIPTION_URL)
  const fileTransferUrl = history.location.pathname === FILE_TRANSFER_URL
  const frozenAssetsUrl = history.location.pathname.includes(FROZEN_ASSETS_ENTITIES_URL)
  const reportingUrl = history.location.pathname.includes(REPORTING_URL)

  const getUserInfos = () => {
    if (!hasUserInfos) {
      customFetch
        .get(API_ME_URL)
        .then((response) => response.json())
        .then((user) => {
          if (user.statusCode === 401) {
            history.push(LOGIN_URL)
          } else {
            setUserInfos(true)

            dispatch({
              type: USER_UPDATE_INFOS,
              user: user,
            })
            return <>{children}</>
          }
        })
    }
  }

  EventEmitter.subscribe(UPDATE_EVENT_USER, () => {
    getUserInfos()
  })

  if (connected || loginUrl || sharedUrl || subscribeUrl || fileTransferUrl) {
    if (frozenAssetsUrl && !user.organization.enableFrozenAssets) {
      return history.push(HOMEPAGE_URL)
    }
    if (reportingUrl && !user.organization.enableReports) {
      return history.push(HOMEPAGE_URL)
    }
    return <>{children}</>
  } else {
    getUserInfos()
  }

  return <></>
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.connected,
    user: state.user,
  }
}

export default connect(mapStateToProps)(withRouter(Middleware))
