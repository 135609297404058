import React, { FC } from 'react'

import { FlagTooltip } from 'component'
import { formatCurrencySymbol, formatPercent } from 'utils'
import { parseFlagDescription, parseInvalidationFlagDescription } from 'utils/commissions'

import { Box, Stack, SxProps, Typography, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface IDocketCurrencyCellProps {
  item: any
  field: string
  value?: any
  showErrors?: boolean
  tag?: string
  fontWeight?: 400 | 500 | 600 | 700
  sx?: SxProps<Theme>
}

const DocketCurrencyCell: FC<React.PropsWithChildren<IDocketCurrencyCellProps>> = ({
  item,
  field,
  value,
  showErrors = true,
  tag = 'td',
  fontWeight = 400,
  sx
}) => {
  if (value === undefined) {
    value = item[field]
  }

  const Element = tag as keyof JSX.IntrinsicElements
  const classNames = ['cell--docket-currency cell-number']

  let fieldFlag = undefined;
  let fieldInvalidationFlag = undefined;
  let hasErrors = false;
  let isInvalidated = false;
  let provider = item.insuranceFeeReport ? item.insuranceFeeReport.insurance.provider : undefined;
  let insuranceName = item.insuranceFeeReport ? item.insuranceFeeReport.insurance.name : '';
  let formattedValue = '-'

  if (!(provider === 'intencial' && field === 'baseAmount' && value === 0.0)) {
    if (item.currency === 'OTHER') {
      formattedValue = formatCurrencySymbol(value, item.currencyOther)
    } else {
      formattedValue = formatCurrencySymbol(value, item.currency)
    }
  }

  let formattedBrokerValue = '-'
  let formattedBrokerRate = '-'

  if (item.brokerAmount) {
    formattedBrokerValue = formatCurrencySymbol(item.brokerAmount, item.currency)
    formattedBrokerRate = formatPercent(item.brokerRate)
  }

  if (item.flagDescription) {
    fieldFlag = parseFlagDescription(item.flagDescription).find(
      (itemFlag) => itemFlag.field === field
    )
    fieldInvalidationFlag = parseInvalidationFlagDescription(
      item.flagInvalidationDescription
    ).find((itemInvalidationFlag) => itemInvalidationFlag.field === field)

    hasErrors = fieldFlag !== undefined
    isInvalidated = fieldInvalidationFlag !== undefined
  }

  if (formattedValue === '-') {
    classNames.push('cell--docket-no-value')
  }

  const isBold = (hasErrors && showErrors && !isInvalidated);

  const CurrencyValueWithBrokerFee = () => (
    <Tooltip
      placement="bottom-end"
      title={
        <Box width={400}>
          <Typography component="p" variant="subtitle1_m" mb={1.5} color="text.primary">
            Commission courtier complémentaire
          </Typography>
          <Typography variant="subtitle1" mb={1} color="text.secondary">
            La commission de <strong>{formattedValue}</strong> correspond uniquement au versement effectué par l’assureur.<br />
            Votre courtier en produit structuré a versé une commission complémentaire de <strong>{formattedBrokerValue}</strong> ({formattedBrokerRate} comme renseigné dans le paramétrage de cet upfront).
          </Typography>
          <Typography variant="subtitle1" mb={1} color="text.secondary" sx={{ fontStyle: 'italic' }}>
            Cette commission complémentaire n’est pas prise en compte ici dans les totaux de {insuranceName} mais est bien prise en compte dans les commissions perçues au travers du client (voir Analyse › Mes clients) et dans vos statistiques globales.
          </Typography>
        </Box>
      }
    >
      <Stack sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted', cursor: 'help' }}>
        {formattedValue}
      </Stack>
    </Tooltip>
  );

  const CurrencyValue = () => (
    <Box
      fontWeight={(fontWeight !== 400 ? fontWeight : isBold ? 500 : 400)}
      color={
        hasErrors && showErrors
          ? isInvalidated
            ? 'text.primary'
            : 'warning.main'
          : 'text.primary'
      }
      sx={sx ? sx: {}}
    >
      {formattedValue}
    </Box>
  );

  return (
    <Element className={classNames.join(' ')} key={`docket-${field}-${item.id}`}>
      <Stack direction="row">
        {showErrors && hasErrors ? (
          <FlagTooltip
            fieldFlag={fieldFlag!}
            fieldInvalidationFlag={fieldInvalidationFlag}
            sx={{ mr: 1, svg: { display: 'block' } }}
          />
        ) : null}

        {item.brokerAmount !== null && field === 'organizationAmount' ? (
          <CurrencyValueWithBrokerFee />
        ) : (
          <CurrencyValue />
        )}
      </Stack>
    </Element>
  )
}

export default DocketCurrencyCell
